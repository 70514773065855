<template>
  <div id="app">
    <!-- 路由占位符 -->
    <router-view></router-view>
  </div>
</template>

<script>

export default {
  name: 'app',
  mounted(){
    console.log(1);
    if(window.sessionStorage.getItem('headIcon')){
      this.$store.commit('setHeadIcon', JSON.parse(window.sessionStorage.getItem('headIcon')))
    }
  }
}
</script>

<style></style>
