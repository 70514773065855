import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

import Home from '@/views/Home'

export const constantRouterMap = [
  {
    path: '/',
    component: Home,
    redirect: '/home',
    children: [
      {
        path: '/home',
        component: () => import('@/views/dashboard/agency_information')
      },
      {
        path: '/resetPsw',
        component: () => import('@/views/user/Update')
      },
      {
        path: '/changeInfo',
        component: () => import('@/views/user/ChangeInfo')
      }
    ]
  },
  {
    path: '/dashboard',
    component: Home,
    children: [
      {
        path: '/dashboard/agency_information',
        component: () => import('@/views/dashboard/agency_information')
      },
      {
        path: '/dashboard/daily_report',
        component: () => import('@/views/dashboard/daily_report')
      },
      {
        path: '/dashboard/hostess',
        component: () => import('@/views/dashboard/hostess')
      }
    ]
  },
  {
    path: '/report',
    component: Home,
    children: [
      {
        path: '/report/total_income',
        component: () => import('@/views/report/total_income')
      },
      {
        path: '/report/hostess_income',
        component: () => import('@/views/report/hostess_income')
      }
    ]
  },
  {
    path: '/login',
    component: () => import('@/views/Login'),
    hidden: true
  }
]

export const async404 = [
  {
    path: '*',
    component: () => import('@/views/404'),
    hidden: true
  }
]

const router = new VueRouter({
  // base: process.env.BASE_URL,
  scrollBehavior: () => ({
    y: 0
  }),
  routes: [...constantRouterMap, ...async404]
})

export default router
