export function bootstrapResponsive() {
  let clientWidth = document.body.clientWidth
  if (clientWidth < 768) {
    return 'xs'
  } else if (clientWidth < 991) {
    return 'sm'
  } else if (clientWidth < 1170) {
    return 'md'
  } else {
    return 'lg'
  }
}
