import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './plugins/element.js'

// 导入全局样式表
import './assets/css/global.css'

import '@/permission.js'
import { CookieEnable } from '@/utils/cookieEnable'

if (!CookieEnable()) {
  alert("Sorry, your browser's cookie function is disabled, please enable it")
}

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
