import request from '@/utils/request'

// 修改密码
export function getDiamondRatio() {
  return request({
    url: '/agent_admin/config/diamond_config',
    method: 'get'
  })
}


