/*
 * @description: 
 * @param: 
 * @return: 
 */
export const homeLang = [
  {
    logout:'Log out'
  },
  {
    logout:'退出'
  }
]

export const loginLang = [
  {
    login: 'Login',
    reset: 'Reset',
    noAccount: 'Incorrect account',
    noPwd: 'Incorrect password'
  },
  {
    login: '登录',
    reset: '重置',
    noAccount: '请输入登录名称',
    noPwd: '请输入登录密码'
  }
]

export const overviewLang = [
  {
    overview: 'Overview',
    hostId: 'Hostess ID',
    download: 'Download',
    agency: 'Agency',
    search: 'Search'
  },

  {
    overview: '总览',
    hostId: '主播ID',
    download: '下载',
    agency: '代理选项',
    search: '查看'
  }
]

export const databaseAgent = [
  {
    agency: 'Agency',
    agencyId: 'Hostess ID',
    searchAgency: 'Search for agency',
    agencyName: 'Agency Name',
    search: 'Search',
    cancel: 'cancel',
    ok: 'ok',
    loading: 'loading',
    password: 'Password',
    totalEarning: 'Total Earning',
    numbersHostess: 'Numbers of Hostess',
    registerDate: 'Register Date',
    add: 'Add',
    cagencyname: 'Agency name',
    confirm: 'Confirm'
  },
  {
    agency: '代理',
    agencyId: '代理ID',
    searchAgency: '搜索代理名',
    agencyName: '代理名',
    search: '查询',
    cancel: '取消',
    ok: '确定',
    loading: '拼命加载中',
    password: '密码',
    totalEarning: '累积金金币数',
    numbersHostess: '主播数量',
    registerDate: '注册时间',
    add: '新增',
    cagencyname: '输入新代理名',
    confirm: '添加新代理'
  }
]

export const databaseHost = [
  {
    hostess: 'Hostess',
    searchAgency: 'Search for agency',
    search: 'Search',
    hostessID: 'Hostess ID',
    sdesertprevail: 'If the ID exists, the ID shall prevail',
    loading: 'loading',
    hostessID2: 'Hostess ID',
    phoneEmail: 'Phone&Email',
    verifyphotos: 'Verify photos',
    registerDate: 'Register Date',
    latestActiveDate: 'Latest Active Date',
    totalOnline: 'Total Online',
    totalEarning: 'Total Earning',
    level: 'Level',
    country: 'Country'
  },
  {
    hostess: '主播',
    searchAgency: '搜索代理名',
    search: '查询',
    hostessID: '请输入主播ID',
    sdesertprevail: '如果ID存在以ID为准',
    loading: '拼命加载中',
    hostessID2: '主播ID',
    phoneEmail: '手机号&邮箱',
    verifyphotos: '认证照片',
    registerDate: '注册时间',
    latestActiveDate: '上次活跃时间',
    totalOnline: '累积在线时长',
    totalEarning: 'Total Earning',
    level: '上期等级',
    country: '地区'
  }
]

export const registered = [
  {
    hostessList: 'Hostess List',
    download: 'Download',
    downloadAll: 'Download All'
  },
  {
    hostessList: '主播注册报表',
    download: '下载',
    downloadAll: '下载全部'
  }
]

export const registeredLight = [
  {
    hostessperformance: 'Hostess performance',
    download: 'Download',
    search: 'Search',
    loading: 'loading'
  },
  {
    hostessperformance: '主播通话曝光表',
    download: '下载',
    search: '查询',
    loading: '拼命加载中'
  }
]

export const registeredEvaluation = [
  {
    agencyName: 'Agency Name',
    download: 'Download',
    search: 'Search',
    loading: 'loading',
    downloadAll: 'Download All'
  },
  {
    agencyName: '代理名称',
    download: '下载',
    search: '查询',
    loading: '拼命加载中',
    downloadAll: '下载全部'
  }
]

export const registeredBlocked = [
  {
    blockedlist: 'Blocked list',
    download: 'Download',
    search: 'Search',
    loading: 'loading',
    date: 'Date',
    userId: 'User ID',
    userName: 'User Name',
    blockdate: 'Block date',
    reason: 'Reason',
    coinsBalance: 'Coins balance',
    active: 'Active',
    unseal: 'Unseal',
    call1:'First call violation image',
    call2:'Second call violation image',
    call3:'Third call violation picture',
  },
  {
    blockedlist: '封禁日志查询',
    download: '下载',
    search: '查询',
    loading: '拼命加载中',
    date: '日期',
    userId: '用户id',
    userName: '用户昵称',
    blockdate: '封禁时间',
    reason: '封禁原因',
    coinsBalance: '剩余金币数',
    active: '操作',
    unseal: '解封',
    call1:'第一次通话违规图片',
    call2:'第二次通话违规图片',
    call3:'第三次通话违规图片',
  }
]

export const childaccountadd = [
  {
    add: 'Add',
    loading: 'loading',
    agentID: 'Agent ID',
    password: 'Password',
    status: 'Status',
    active: 'Active',
    delete: 'Delete',
    addSubAccount: 'Add sub account'
  },
  {
    add: '添加',
    loading: '拼命加载中',
    agentID: '代理ID',
    password: '密码',
    status: '状态',
    active: '操作',
    delete: '删除',
    addSubAccount: '添加子账号'
  }
]
