export function copyUrl(url) {
  var domUrl = document.createElement('input')
  domUrl.value = url
  domUrl.id = 'creatDom'
  document.body.appendChild(domUrl)
  domUrl.select() // 选择对象
  document.execCommand('Copy') // 执行浏览器复制命令
  let creatDom = document.getElementById('creatDom')
  creatDom.parentNode.removeChild(creatDom)
}
