import Vue from 'vue'
import Vuex from 'vuex'
import { login, logout, getInfo } from '@/api/login'
import { bootstrapResponsive } from '@/utils/bootstrapResponsive.js'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    // 后台菜单
    // 中文菜单栏
    menus: [],
    // 英文菜单栏
    menulistEn: [],
    // 权限
    level: '',
    // 动态路由
    actMenus: [],
    // 语言
    lang: '',
    // 当前尺寸
    bootstrapResponsive: bootstrapResponsive(),
    diamondRatio: 1,
    headIcon: ''
  },
  getters: {
    getCurDiamondNum: (state) =>
      (num) => {
        let realNum = num
        if(realNum === void 0 || realNum === null) realNum = 0
        return Math.ceil(realNum * state.diamondRatio) 
      }
  },
  mutations: {
    SET_MENUS: (state, menus) => {
      state.menus = menus
    },
    SET_ACTMENUS: (state, actMenus) => {
      state.actMenus = actMenus
    },
    setDiamondRatio(state, val) {
      state.diamondRatio = val
    },
    setHeadIcon(state, val){
      console.log(val);
      state.headIcon = val
    }
  },
  actions: {},
  modules: {}
})
