//  TEST  ro  PRODUCTION
const type = 'PRODUCTION'

let httpUrl,
  admHttpUrl = ''
switch (type) {
  // 测试服
  case 'TEST':
    httpUrl = 'https://testvideochat.kissu.site'
    admHttpUrl = 'http://test.kissu.site:18080'

    break
  // 正式服
  case 'PRODUCTION':
    httpUrl = 'https://videochat.kissu.site'
    admHttpUrl = 'https://www.kissu.site'
    break
}

// Http Url
export const CINFIG_HTTPURL = httpUrl

// Http admin Url
export const CINFIG_HTTPURL_ADMIN = admHttpUrl
