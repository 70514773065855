/*
 * @description:
 * @param:
 * @return:
 */
import axios from 'axios'
import { Message, MessageBox } from 'element-ui'
import { CINFIG_HTTPURL } from '@/config'

// 测试服
// https://testvideochat.kissu.site
// 正式服
// https://videochat.kissu.site
// 创建axios实例
const service = axios.create({
  baseURL: CINFIG_HTTPURL // api的base_url
  // timeout: 15000 // 请求超时时间
  // withCredentials: true
})

// request拦截器
service.interceptors.request.use(
  config => {
    if (sessionStorage.getItem('loginData')) {
      let loginData = JSON.parse(sessionStorage.getItem('loginData'))
      config.params = {
        token: loginData.token,
        username: loginData.agentName,
        agentId: loginData.agentId,
        ...config.params
      }
    }
    return config
  },
  error => {
    // Do something with request error
    Promise.reject(error)
  }
)

// respone拦截器
service.interceptors.response.use(
  response => {
    /**
     * code为非0是抛错 可结合自己业务进行修改
     */
    const res = response.data
    if (res.code !== 0) {
      // Message({
      //   message: res.msg,
      //   type: 'error',
      //   duration: 3 * 1000
      // })

      // 1001:未登录;
      if (res.code === 1001) {
        MessageBox.confirm(
          '你已被登出，可以取消继续留在该页面，或者重新登录',
          '确定登出',
          {
            confirmButtonText: '重新登录',
            cancelButtonText: '取消',
            type: 'warning'
          }
        ).then(() => {
          window.sessionStorage.clear()
          location.reload() // 为了重新实例化vue-router对象 避免bug
        })
      }
      return response
    } else {
      return response
    }
  },
  error => {
    // console.log('err' + error)// for debug
    Message({
      message: error.message,
      type: 'error',
      duration: 3 * 1000
    })
    return Promise.reject(error)
  }
)

export default service
