<template>
  <el-container class="home-container">
    <!-- 侧边栏 -->
    <el-aside :width="isCollapse ? '64px' : '265px'" class="hidden-xs-only">
      <div class="totle">
        <img :src="$store.state.headIcon || require('../assets/logo.png')" alt />
        <span class="notranslate">{{ username }}</span>
      </div>
      <div class="totle2">
        invite code: <span>{{ code }}</span>
      </div>
      <div class="homebtn">
        <el-button class="homebtn1" type="primary" @click="mCopyUrl" size="mini">
          Copy
        </el-button>
        <el-button class="homebtn1" type="primary" @click="goChangeInfo" size="mini">
          Change information
        </el-button>
        <el-button class="homebtn1" type="warning" @click="goEdit" size="mini">
          Change password
        </el-button>
      </div>
      <!-- 侧边栏菜单区 -->
      <el-menu background-color="#333744" text-color="#fff" active-text-color="#409Eff" unique-opened
        :collapse="isCollapse" :collapse-transition="false" router :default-active="activePath">
        <!-- 一级菜单 -->
        <el-submenu :index="item.id + ''" v-for="item in menulistEn" :key="item.id">
          <!-- 一级菜单模板区 -->
          <template slot="title">
            <!-- 图标 -->
            <i :class="iocnsObj[item.id]"></i>
            <!-- 文本 -->
            <span>{{ item.authName }}</span>
          </template>

          <!-- 二级菜单 -->
          <el-menu-item :index="subItem.path" v-for="subItem in item.children" :key="subItem.id"
            @click="saveNavState(subItem.path)">
            <!-- 图标 -->
            <i class="el-icon-menu"></i>
            <span>{{ subItem.authName }}</span>
          </el-menu-item>
        </el-submenu>
      </el-menu>
    </el-aside>

    <el-container>
      <!-- 头部主题区 -->
      <el-header>
        <div class="toggle-button hidden-sm-and-up" @click="toggleCollapse">
          |||
        </div>
        <div></div>
        <div>
          <el-badge :value="cout" class="item er">
            <i @click="round" class="el-icon-chat-dot-round"></i>
          </el-badge>
          <el-button type="info" class="heald-btn" @click="logout">{{
            langData.logout
          }}</el-button>
        </div>
      </el-header>
      <!-- 右侧主题区 -->
      <el-main :class="$store.state.bootstrapResponsive == 'xs' ? 'el-main-xs' : ''">
        <!-- 路由占位符 -->
        <transition name="fade" mode="out-in">
          <router-view></router-view>
        </transition>
      </el-main>
    </el-container>
    <el-dialog title="提示" :visible.sync="dialogVisible" width="30%" :before-close="handleClose">
      <div v-for="(item, i) in list" :key="i" class="div">
        <div>{{ i + 1 }} .</div>
        <div>
          <span>时间: {{ item.date }}</span>
          <div>内容: {{ item.agentInformText }}</div>
        </div>
      </div>
    </el-dialog>
    <el-drawer custom-class="drawer-mecss" :visible.sync="drawer" :direction="direction" :withHeader="false" size="265px">
      <div class="xs-menu">
        <div class="totle">
          <img :src="$store.state.headIcon || require('../assets/logo.png')" alt />
          <span>{{ username }}</span>
        </div>
        <div class="totle2">
          invite code: <span>{{ code }}</span>
        </div>
        <div class="homebtn">
          <el-button class="homebtn1" type="primary" @click="mCopyUrl" size="mini">
            Copy
          </el-button>
          <el-button class="homebtn1" type="primary" @click="goChangeInfo" size="mini">
          Change information
        </el-button>
          <el-button class="homebtn1" type="warning" @click="goEdit" size="mini">
            Change password
          </el-button>
        </div>
        <!-- 侧边栏菜单区 -->
        <el-menu background-color="#333744" text-color="#fff" active-text-color="#409Eff" unique-opened
          :collapse="isCollapse" :collapse-transition="false" router :default-active="activePath">
          <!-- 一级菜单 -->
          <el-submenu :index="item.id + ''" v-for="item in menulistEn" :key="item.id">
            <!-- 一级菜单模板区 -->
            <template slot="title">
              <!-- 图标 -->
              <i :class="iocnsObj[item.id]"></i>
              <!-- 文本 -->
              <span>{{ item.authName }}</span>
            </template>

            <!-- 二级菜单 -->
            <el-menu-item :index="subItem.path" v-for="subItem in item.children" :key="subItem.id"
              @click="saveNavState(subItem.path, 2)">
              <!-- 图标 -->
              <i class="el-icon-menu"></i>
              <span>{{ subItem.authName }}</span>
            </el-menu-item>
          </el-submenu>
        </el-menu>
      </div>
    </el-drawer>
  </el-container>
</template>

<script>
import { homeLang } from '@/utils/lang.js'
import { getDiamondRatio } from '../api/base';
import { count_unreadMsg, query_unreadMsg } from '@/api/login.js'
import { copyUrl } from '@/utils/copyUrl.js'

export default {
  data() {
    return {
      list: [],
      // 语言包
      langData: homeLang[0],
      username: JSON.parse(sessionStorage.getItem('loginData')).agentName,
      code: JSON.parse(sessionStorage.getItem('loginData')).inviteCode,
      avatar: JSON.parse(sessionStorage.getItem('loginData')).headIcon,
      // adm菜单
      menulistEn: [
        {
          authName: 'Dashboard',
          id: 3,
          order: 1,
          path: 'dashboard',
          children: [
            {
              id: 31,
              authName: 'Agency information',
              path: '/dashboard/agency_information',
              order: null
            },

            {
              id: 33,
              authName: 'Hostess',
              path: '/dashboard/hostess',
              order: null
            }
          ]
        },
        {
          authName: 'Report',
          id: 4,
          order: 1,
          path: 'report',
          children: [
            {
              id: 41,
              authName: 'Total Income',
              path: '/report/total_income',
              order: null
            },

            {
              id: 42,
              authName: 'Daily report',
              path: '/dashboard/daily_report',
              order: null
            }
          ]
        }
      ],
      iocnsObj: {
        1: 'el-icon-s-home',
        2: 'el-icon-coin',
        3: 'el-icon-coin',
        4: 'el-icon-download',
        5: 'el-icon-coin',
        6: 'el-icon-key'
      },
      direction: 'ltr',
      drawer: false,
      // 是否折叠
      isCollapse: false,
      activePath: '',
      // 语言
      lang: 2,
      cout: 0,
      dialogVisible: false
    }
  },
  async created() {
    const { data: res } = await getDiamondRatio()
    if (res.code === 0) {
      this.$store.commit('setDiamondRatio', res.data)
    } else {
      this.$store.commit('setDiamondRatio', 1)
    }

    this.saveNavState(this.$route.path)
    this.activePath = window.sessionStorage.getItem('activePath') || ''
    this.getHostListData()
  },
  methods: {
    // 复制
    mCopyUrl() {
      copyUrl(this.code)
      this.$message({
        message: 'copy success',
        center: true,
        type: 'success'
      })
    },
    goEdit() {
      this.$router.push('/resetPsw')
    },
    goChangeInfo(){
      this.$router.push('/changeInfo')
    },
    async getHostListData() {
      const { data: res } = await count_unreadMsg({
        agent_id: JSON.parse(sessionStorage.getItem('loginData')).agentId
      })
      this.cout = res.data.data
    },
    async query() {
      const { data: res } = await query_unreadMsg({
        agent_id: JSON.parse(sessionStorage.getItem('loginData')).agentId
      })
      this.list = res.data
    },
    handleClose() {
      this.dialogVisible = false
    },
    round() {
      this.query()
      this.dialogVisible = true
    },
    // 登出
    async logout() {
      window.sessionStorage.clear()
      location.reload()
    },
    // 点击按钮实现菜单折叠展开
    toggleCollapse() {
      this.drawer = true
    },
    // 保存连接的激活状态
    saveNavState(activePath, type) {
      // xs 下点击菜单需隐藏菜单栏
      if (type == 2) {
        this.drawer = false
      }
      window.sessionStorage.setItem('activePath', activePath)
      this.activePath = activePath
    }
    // // 语言切换
    // switchLang() {
    //   window.sessionStorage.setItem('lang', this.lang)
    //   this.$store.state.lang = this.lang
    //   this.menulist =
    //     this.lang == 1 ? this.$store.state.menus : this.$store.state.menulistEn
    //   this.langData = homeLang[this.lang]
    // }
  }
}
</script>

<style lang="scss" scoped>
.home-container {
  height: 100%;
}

.el-header {
  display: flex;
  justify-content: space-between;
  background-color: #373d41;
  color: #fff;
  font-size: 20px;
  padding: 0;
}

.heald-btn {
  margin: 10px 20px;
}

.el-select {
  width: 100px;
  margin-right: 15px;
}

.totle {
  width: 100%;
  height: 140px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  color: #fff;

  img {
    width: 60px;
    height: 60px;
    border-radius: 50%;
  }
}

.totle2 {
  text-align: center;
  color: #fff;

  span {
    color: #ff0000;
  }
}

.el-aside {
  background-color: #333744;

  .el-menu {
    border-right: none;
  }
}

.el-menu {
  border-right: none;
}

.el-main {
  background-color: #eaedf1;
}

.el-main-xs {
  padding: 0;
}

.iconfont {
  margin-right: 13px;
}

.toggle-button {
  width: 60px;
  background-color: #4a5064;
  font-size: 10px;
  line-height: 60px;
  color: #fff;
  text-align: center;
  letter-spacing: 0.2em;
  cursor: pointer;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.28s;
}

.fade-enter,
.fade-leave-active {
  opacity: 0;
}

/*fade*/
.breadcrumb-enter-active,
.breadcrumb-leave-active {
  transition: all 0.5s;
}

.breadcrumb-enter,
.breadcrumb-leave-active {
  opacity: 0;
  transform: translateX(20px);
}

.breadcrumb-move {
  transition: all 0.5s;
}

.breadcrumb-leave-active {
  position: absolute;
}

.homebtn {
  width: 100%;
  margin: 8px auto;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  .homebtn1 {
    flex-shrink: 0;
    display: inline-block;
    width: 150px;
    margin-top: 8px;
    margin-left: 0;
  }
}

.xs-menu {
  width: 265px;
  background-color: #333744;
  height: 100vh;
}

.drawer-mecss {
  background-color: #333744;
}

.ps {
  margin: 0 0 20px;

  p {
    width: 1000px;
    margin: 0 auto 10px;
  }
}

.div {
  font-size: 16px;
  margin: 0 0 20px;
  display: flex;

  > :nth-child(2) {
    margin: 0 20px;

    >div {
      margin: 10px 0 0;
      line-height: 25px;
    }
  }
}

.er {
  cursor: pointer;
}
</style>
