/*
 * @description: 
 * @param: 
 * @return: 
 */
import request from '@/utils/request'


// 登录
export function login(username, pwd, curTime) {
  return request({
    url: '/agent_admin/login',
    method: 'post',
    data: {
      username,
      pwd,
      curTime
    }
  })
}
// 代理通知
export function count_unreadMsg(params) {
  return request({
    url: '/agent_admin/msgnotification/count_unreadMsg',
    method: 'get',
    params
  })
}

// 代理通知
export function query_unreadMsg(params) {
  return request({
    url: '/agent_admin/msgnotification/query_unreadMsg',
    method: 'get',
    params
  })
}
